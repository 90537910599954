.HomePage{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 30%;
    transform: translate(-40%, -50%);
    @media screen and (max-width: 1000px){
        transform: translate(-50%, -50%);
    }
    .home-text{
        color: white;
        text-align: center;
        font-size: 3.6rem;
        @media screen and (max-width: 460px){
            font-size: 3rem;
        }
        @media screen and (max-width: 385px){
            font-size: 2.5rem;
        }
        span{
            color: #037FFF;
        }
    }
    .home-subtext{
        text-align: center;
        font-size: 1.5rem;
        @media screen and (max-width: 460px){
            font-size: 1.4rem;
        }
        @media screen and (max-width: 385px){
            font-size: 1.2rem;
        }
    }
}

.ParticlesBackground{
    position: absolute;
    top: 0;
    left: 0;
    width: 84vw;
    height: 100vh;
    opacity: 0.5;
}
