.SkillSection{
    margin: 4rem 0;
    padding: 0;
    .carousel-button{
        color: #a4acc4;
        font-size: 2rem;
    }
    .skill-category{
        width: 56vw;
        padding: 0 1rem;
        margin: 0;
        background-color: #191D2B;
        border-left: 1px solid #2e344e;
        border-right: 1px solid #2e344e;
        border-bottom: 1px solid #2e344e;
        border-top: 8px solid #2e344e;
        transition: all .4s ease-in-out;
        &:hover{
            border-top: 8px solid #037FFF;
        }
        .category-title{
            width: 100%;
            font-size: 1.6rem;
            font-weight: 500;
            text-align: center;
            margin-top: .5rem;
            margin-bottom: 1.5rem;
            @media screen and (max-width: 800px){
                font-size: 1.4rem;
            }
        }
        .category-cards{
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: .5rem;
            row-gap: 1.5rem;
            margin: 1rem 0;
            @media screen and (max-width: 1100px){
                grid-template-columns: repeat(3, 1fr);
            }
            @media screen and (max-width: 500px){
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

.SkillCard{
    text-align: center;
}
