.PortfolioMenu {
  width: 100%;
  .portfolio {
    background-color: #161925;
    width: 100%;
    padding: 1rem;
    overflow: hidden;
    .image-data {
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        object-fit: cover;
        transition: all 0.2s ease-in-out;
        &:hover {
          transform: scale(1.1);
        }
        padding-bottom: 0.6rem;
      }
    }
    .title {
      font-size: 1.3rem;
      color: white;
      font-weight: 500;
      margin-bottom: 0.2rem;
    }
    .subtitle {
      font-size: 1rem;
      color: white;
    }
    hr {
      margin: 0.6rem 0;
      color: white;
    }
    p {
      font-size: 1rem;
    }
    .hidden-link {
      display: none;
    }
    .description {
      margin-bottom: 0.3rem;
    }
    .bottom {
      .left {
        float: left;
        .techstack {
          color: white;
        }
        .published {
        }
      }
      .right {
        float: right;
        margin-top: 0.3rem;
        a {
          margin-left: 0.6rem;
        }
      }
    }
  }
}

.PortfolioFilter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0;
  .category-button {
    padding: 0.6rem 2rem;
    background-color: #0381ff5b;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    color: white;
    transition: all 0.3s ease-in-out;
    margin: 0 0.5rem 0.5rem 0rem;
    &:hover {
      background-color: #037fff;
    }
    &:active {
      background-color: #037fff;
    }
    &:focus {
      background-color: #037fff;
    }
  }
}
