.App{
    .sidebar{
        width: 16%;
        height: 100vh;
        background-color: #191D2B;
        position: fixed;
        z-index: 11;
        border-right: 1px solid #2e344e;
        transform-origin: left;
        @media screen and (max-width: 1000px){
            transition: all .4s ease-in-out;
            transform: translateX(-100%);
            width: 30%;
        }
        @media screen and (max-width: 411px){
            width: 50%;
        }
    }
    .nav-toggle{
        @media screen and (max-width: 1000px){
            transition: all .4s ease-in-out;
            transform: translateX(0);
        }
    }

    .main-content{
        width: 100%;
        background-color: #10121B;
        min-height: 100vh;
        // background-image: url(../img/dots.svg);
        display: grid;
        position: relative;
        .content{
            width: 60%;
            margin: 5rem 0;
            margin-left: 28.5%;
            @media screen and (max-width: 1000px){
                width: 75%;
                margin: 3rem 0;
                margin-left: 13%;
            }
        }
        @media screen and (max-width: 1000px){
            margin-left: 0;
            width: 100%;
        }
    }
}

.nav-btn{
    position: absolute;
    z-index: 10;
    right: 11%;
    top: 3.6rem;
    width: 4rem;
    height: 4rem;
    display: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    @media screen and (max-width: 1000px){
        display: block;
    }
    @media screen and (max-width: 480px){
        display: block;
        width: 3rem;
    }
    .lines-1, .lines-2, .lines-3{
        height: .4rem;
        width: 3.5rem;
        background-color: #3858cc;
        pointer-events: none;
        display: none;
        border-radius: 20px;
        &:not(:last-child){
            margin-bottom: .5rem;
        }
        @media screen and (max-width: 1000px){
            display: block;
        }
        @media screen and (max-width: 480px){
            display: block;
            width: 3rem;
        }
    }
}

.my-button{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    padding: 1rem 2rem;
    background-color: #037FFF;
    outline: none;
    border: none;
    font-family: inherit;
    font-size: 1.2rem;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 2px;
    margin-top: 1rem;
    &::after{
        position: absolute;
        content: "";
        width: 0;
        height: .3rem;
        left: 0;
        bottom: 0;
        background-color: #a4acc4;;
        transition: all 0.4s ease-in-out;
    }
    &:hover::after{
        width: 100%;
    }
    @media screen and (max-width: 460px){
        font-size: 1.1rem;
    }
}
