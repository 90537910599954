.IntroSection{
    display: flex;
    margin-top: 4rem;
    margin-bottom: 4rem;
    .about-img{
        width: 100%;
        min-width: 20%;
        height: 40vh;
        max-height: 45vh;
        margin: auto;
        img{
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }

    .about-info{
        margin-left: 2rem;
        h4{
            font-size: 2rem;
        }
        .about-text{
            padding: 1rem 0;
            text-align: justify;
        }
    }
    @media screen and (max-width: 1200px){
        flex-direction: column;
        .about-info{
            margin-left: 0;
            margin-top: 1rem;
        }
    }
    
}
